<template>
	<layouts-cart-minimum>
		<div class="pb-24">
			<div class="list-group list-group-flush">
				<cart-item v-for="(item, index) in items" v-bind:key="item.id" :item="item" :index="index" />
				<div class="list-group-item px-0">
					<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
						<h6 class="h6">Subtotal</h6>
						<h6 class="h6 text-nowrap">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</h6>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
						<h6 class="h6 text-danger">Descuento</h6>
						<h6 class="h6 text-danger text-nowrap">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</h6>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="(type && type.option == 'DELIVERY')">
						<h6 class="h6">Envío</h6>
						<h6 class="h6 text-nowrap">{{ vueNumberFormat(deliveryAmount, $personalizer.currency.value) }}</h6>
					</div>
					<div class="d-flex justify-content-between">
						<h4 class="h4">Total</h4>
						<h4 class="h4 text-nowrap">{{ vueNumberFormat(total, $personalizer.currency.value) }}</h4>
					</div>
				</div>
			</div>
			<router-link :to="{ name: 'store.home', params: { slug: slug } }" class="btn btn-neutral w-100 mt-8" v-if="isDefined">Agregar más productos</router-link>
			<a href="javascript:void(0);" class="btn btn-outline-danger w-100 mt-5" @click="deleteOrder">Descartar pedido</a>
		</div>

		<div class="fixed-bottom bg-white">
			<div class="container py-4">
				<div class="row">
					<div class="d-grid gap-2 col-12 col-md-6 mx-auto">
						<button class="btn btn-dark" type="button" @click="submit">Continuar</button>
					</div>
				</div>
			</div>
		</div>
	</layouts-cart-minimum>
</template>

<script>
	import { computed, watch } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const isDefined = computed(() => store.getters['cart/isDefined']);
			const slug = computed(() => store.getters['cart/slug']);
			const type = computed(() => store.getters['cart/type']);
			const items = computed(() => store.getters['cart/items']);
			const subtotal = computed(() => store.getters['cart/subtotal']);
			const discount = computed(() => store.getters['cart/discount']);
			const deliveryAmount = computed(() => store.getters['cart/deliveryAmount']);
			const total = computed(() => store.getters['cart/total']);
			const orderRequestFilters = computed(() => store.getters['auth/orderRequestFilters']);
			const deleteOrder = () => store.dispatch('cart/empty');

			const submit = () => {
				if(orderRequestFilters.value !== null) router.push({name: orderRequestFilters.value.name});
				router.push({ name: 'cart.checkout' })
			}

			watch(isDefined, (newValue) => {
				if(!newValue) redirectBack();
			});

			return { isDefined, slug, type, items, subtotal, discount, deliveryAmount, total, deleteOrder, submit };
		}
	}
</script>

<style scoped>
	.container {
		max-width: 650px !important;
	}
</style>